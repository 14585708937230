/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyD1kAZ-q3PymiIEmvKDUVIcY1MhWRHQA18",
  "appId": "1:670282132064:web:bae23af970352189d8f105",
  "authDomain": "schooldog-i-piedmont-al.firebaseapp.com",
  "measurementId": "G-H56BGGF505",
  "messagingSenderId": "670282132064",
  "project": "schooldog-i-piedmont-al",
  "projectId": "schooldog-i-piedmont-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-piedmont-al.appspot.com"
}
